import React, { createContext, useState, useContext } from 'react';

// Create Context
const AuthContext = createContext();

// Custom Hook to use AuthContext
export const useAuth = () => useContext(AuthContext);

// Provider Component
export const AuthProvider = ({ children }) => {
  const [usernameGlobal, setUsernameGlobal] = useState('');

  return (
    <AuthContext.Provider value={{ usernameGlobal, setUsernameGlobal }}>
      {children}
    </AuthContext.Provider>
  );
};
