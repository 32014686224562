import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import 'antd/dist/reset.css';
import Sidebar from './components/sidebar';
import Header from './components/header';
// import LibraryUpload from './components/libraryUpload';
// import LibraryFiles from './components/libraryFiles';
import ODPOPSSheet from './components/odpopsSheet';
import ODPOPSQueue from './components/odpopsQueue';
import Downloads from './components/downloads';
import Login from './components/login';
import Logout from './components/logout';
import axios from 'axios';
import Billing from './components/billing';
import config from './config';
import { AuthProvider } from './AuthContext'; // Import the context


const { Content } = Layout;

function App() {
  const [collapsed, setCollapsed] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [username, setUsername] = useState('');
  // const [password, setPassword] = useState('');
  const [token, setToken] = useState(''); 
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = async () => {
    setIsLoggedIn(false);
    console.log(token)
    // axios.post('http://127.0.0.1:4000/v1/auth/logout', {refreshToken: token.refresh.token});
    axios.post(config.BASE_URL + '/v1/auth/logout', {refreshToken: token.refresh.token}, { headers: {
      'ngrok-skip-browser-warning': '1231',
      'bypass-tunnel-reminder': '1234'
    }});
   

  };

  const handleLogin = async (username,password ) => {
    try {
        // const response = await axios.post('http://127.0.0.1:4000/v1/auth/login', { "email": username, password })
        const response = await axios.post(config.BASE_URL+'/v1/auth/login', { "email": username, password }, {
          headers: {
            'ngrok-skip-browser-warning': '1231',
            'bypass-tunnel-reminder': '1234'
          }
        });
        const { tokens } = response.data;
        console.log({data: response.data.tokens})
        setToken(tokens);
        setIsLoggedIn(true);
        return
      //  return <Navigate to="/odpops/sheet" replace />;
    } catch (error) {
        console.error('Login failed:', error.response);
    }
};

const handleProtectedRoute = async () => {
    try {
        const response = await axios.get(config.BASE_URL+'/v1/protected', {
            headers: { Authorization: `Bearer ${token}` }
        });
        console.log(response.data);
    } catch (error) {
        console.error('Access to protected route failed:', error.response.data);
    }
};


  return (
    <AuthProvider>
    <Router>
      <Layout style={{ minHeight: '100vh' }}>
        {isLoggedIn && <Sidebar collapsed={collapsed} onCollapse={toggleCollapse} />}
        <Layout className="site-layout">
        {isLoggedIn && <Header/>}
          <Content style={{ margin: '0 16px' }}>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
              <Routes>
                {isLoggedIn ? (
                  <>
                    {/* <Route path="/library/upload" element={<LibraryUpload />} /> */}
                    {/* <Route path="/library/files" element={<LibraryFiles />} /> */}
                    <Route path="/odpops/sheet" element={<ODPOPSSheet />} />
                    <Route path="/odpops/queue" element={<ODPOPSQueue />} />
                    <Route path="/downloads" element={<Downloads />} />
                    <Route path="/logout" element={<Logout onLogout={handleLogout} />} />
                    <Route path="/billing" element={<Billing/>} />
                  </>
                ) : (
                  <Route path="*" element={<Navigate to="/login" replace />} />
                )}
                <Route path="/login" element={<Login onLogin={handleLogin} />} />
              </Routes>
            </div>
          </Content>
        </Layout>
      </Layout>
    </Router>
    </AuthProvider>
  );
}

export default App;
