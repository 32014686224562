import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './../AuthContext'; // Import Auth Context

const useStyles = makeStyles((theme) => ({
    
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = ({ onLogin }) => {
    const navigate = useNavigate();
  const classes = useStyles();
  const { setUsernameGlobal } = useAuth(); // Get setUsername from Context
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("login")
    // Here you can perform your login logic
    await onLogin(username, password);
    setUsernameGlobal(username); // Set global username
    console.log("redirecting")
    navigate('/odpops/sheet');
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        {/* <LockOutlinedIcon className={classes.avatar} /> */}
        {/* <Typography component="h1" variant="h5">
          Sign in
        </Typography> */}
        <div className="logo" style={{ height: 'auto', margin: '16px' }}>
        <img src="/logo192.png" alt="Logo" style={{ width: '100%', background: 'blue', marginBottom: '10px' }} /> 
      </div>
        
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default Login;
